import {
  AskConfirmation,
  confirmationButtons,
  ConfirmationButtonType
} from '../../../../webmodule-common/other/ui/modal-confirmation.js';
import { cache } from '../../cache-impl/cache-registry.js';
import {
  createStagedEvent,
  defaultTestInterval,
  EventCancellation
} from '../../../../webmodule-common/other/general/staged-event.js';
import { customElement } from 'lit/decorators.js';
import {
  DocumentCategory,
  DocumentState,
  ResourceType,
  ViewProjectDocument
} from '../../../api/dealer-api-interface-project.js';
import { emptyGuid } from '../../../../webmodule-common/other/api/guid.js';
import { EventResource } from '../../../projects/views/project-resource-view.js';
import { EventTemplate, Snippet } from '../../../../webmodule-common/other/ui/events.js';
import { fireQuickSuccessToast } from '../../../../webmodule-common/other/toast-away.js';
import { getEnum } from '../../../../webmodule-common/other/FormInputView.js';
import { getPurchaseOrderNumberFormatted } from '../../../purchase-orders/data/purchase-order-helper-functions.js';
import { getQuoteNumberFormatted } from '../../../quotes/data/quote-helper-functions.js';
import { html, TemplateResult } from 'lit';
import { ItemReference } from '../../../../webmodule-common/cache/definitions/cache-item-reference.js';
import { LitTableWrapper } from '../../../../webmodule-common/other/webmodule-common.js';
import { notificationSignal } from '../../../../webmodule-common/other/ui/icons/icon-notification-signal.js';
import { ProjectContainerManager } from '../../../projects/data/project-container.js';
import { ProjectDocumentView, ProjectDocumentViewOptions } from '../../../projects/views/project-document-view.js';
import { ProjectResourceLink } from '../../cache-impl/resource-link.js';
import { PurchaseOrder } from '../../../api/dealer-api-interface-franchisee.js';
import { QuoteCacheData } from '../../cache-impl/cache-data.js';
import { refreshButton } from '../../../../webmodule-common/other/ui/command-action.js';
import { RequestPage, ResultPaginated } from '../../../../webmodule-common/other/ui/RequestPage.js';
import { resolveURL } from '../../../../webmodule-common/other/ui/resource-resolver.js';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher.js';
import { resourceQuote } from '../../../quotes/ui/launcher.js';
import { SSEDocumentTracker } from '../../../projects/data/sse-documenttracker.js';
import { tlang } from '../../../../webmodule-common/other/language/lang.js';
import {
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebmoduleSelectEvent
} from '../../../../webmodule-common/components/src/webmodule-components.js';
import { when } from 'lit/directives/when.js';
import { WMEventSource } from '../../../api/event-source.js';

interface FranchiseeProjectDocumentTableOptions {
  projectManager: ProjectContainerManager;
  deleteDocumentEvent: EventResource;
}

interface ProjectDocumentRecordView {
  id: string;
  dateCreated: string;
  state: DocumentState;
  category: DocumentCategory;
  isDocument: boolean;
  resourceType: ResourceType;
  resourceId: string;
  description: string;
  publicFileURL: string;
}

@customElement('wm-franchiseeprojectdocumenttable')
export class FranchiseeProjectDocumentTable extends LitTableWrapper<ProjectDocumentRecordView> {
  projectManager: ProjectContainerManager;
  projectResourceLinkCache = cache().projectResourceLink;
  quoteCache = cache().quote;
  purchaseOrderCache = cache().purchaseOrder;
  userCache = cache().userProfile;
  deleteDocumentEvent: EventResource;

  constructor(options: FranchiseeProjectDocumentTableOptions) {
    super();
    this.projectManager = options.projectManager;
    this.deleteDocumentEvent = options.deleteDocumentEvent;
  }

  public verticalHeight(): string | undefined {
    return undefined;
  }
  public forceRefresh(): void {
    this.projectManager.clearContainer();
  }
  public async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<ProjectDocumentRecordView>> {
    await this.projectManager.needsProject();
    const items = this.projectManager.container.documents;

    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    const resourceKeys = items.map(x => x.documentTracker.resourceId ?? emptyGuid) ?? [];

    await this.projectResourceLinkCache
      .getMany(resourceKeys)
      .then(async (data: ItemReference<ProjectResourceLink>[] | null) => {
        if (!data) return;

        const qKeys = data?.filter(x => x.data.resourceType === ResourceType.Quote).map(x => x.data.quoteId);
        const pKeys = data
          ?.filter(x => x.data.resourceType === ResourceType.PurchaseOrder)
          .map(x => x.data.purchaseOrderId);

        const qCachePreFetch = this.quoteCache
          .getMany(qKeys)
          .then(async (data: ItemReference<QuoteCacheData>[] | null) => {
            const uKeys = data?.map(x => x.data.quoteSummary.creationUserId);

            return this.userCache.preFetch(uKeys ?? []);
          });

        // eslint-disable-next-line consistent-return
        return await Promise.all([qCachePreFetch, this.purchaseOrderCache.preFetch(pKeys)]);
      });

    const getResourceNumber = (a: ViewProjectDocument) => {
      let number = 0;
      switch (a.documentTracker.resourceType) {
        case ResourceType.Quote:
          number = this.quoteCache.getLocal(a.documentTracker.resourceId)?.data.quoteSummary.quoteNumber ?? 0;
          break;
        case ResourceType.PurchaseOrder:
          number =
            this.purchaseOrderCache.getLocal(a.documentTracker.resourceId)?.data.purchaseOrder.purchaseOrderNumber ?? 0;
          break;
        default:
          break;
      }

      return number;
    };

    const documentCompare = (a: ViewProjectDocument, b: ViewProjectDocument) => {
      const aRType = a.documentTracker.resourceType;
      const bRType = b.documentTracker.resourceType;
      const aRNum = getResourceNumber(a);
      const bRNum = getResourceNumber(b);

      const typeComparison = (aRType === bRType ? 0 : aRType > bRType ? 1 : -1) * 100;
      const numberComparison = (aRNum === bRNum ? 0 : aRNum > bRNum ? 1 : -1) * 10;
      const dateComparison = b.documentTracker.dateCreated.localeCompare(a.documentTracker.dateCreated);

      return typeComparison + numberComparison + dateComparison;
    };

    const results = this.convertItems(items?.sort((a, b) => documentCompare(a, b)));

    return {
      count: results.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: results.length,
      results: results
    };
  }

  convertItems(items: ViewProjectDocument[]): ProjectDocumentRecordView[] {
    const results: ProjectDocumentRecordView[] = [];
    let lastResourceId = emptyGuid;
    for (const item of items) {
      if (lastResourceId !== item.documentTracker.resourceId) {
        lastResourceId = item.documentTracker.resourceId;
        results.push({
          id: emptyGuid,
          category: DocumentCategory.Permanent,
          dateCreated: Date.now().toLocaleString(),
          isDocument: false,
          state: DocumentState.Available,
          resourceType: item.documentTracker.resourceType,
          resourceId: lastResourceId,
          description: '',
          publicFileURL: ''
        });
      }
      results.push({
        id: item.documentTracker.id,
        category: item.documentTracker.category,
        dateCreated: item.documentTracker.dateCreated,
        isDocument: true,
        state: item.documentTracker.state,
        resourceType: item.documentTracker.resourceType,
        resourceId: item.documentTracker.resourceId,
        description: item.documentTracker.description,
        publicFileURL: item.publicFileURL
      });
    }
    return results;
  }

  public getColumns(): WebModuleLitTableColumnDef[] {
    return [
      {
        title: tlang`Creation Date`,
        fieldName: 'documentTracker.dateCreated',
        sortable: true,
        classes: 'colpx-550 colpxmax-550 document-creation-date',
        displayValue: (_table: WebModuleLitTable, item: ProjectDocumentRecordView) => {
          if (item.isDocument) {
            const dt = new Date(item.dateCreated);
            return html`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
          }
          return html`${this.getResourceLink(`${item.resourceType};${item.resourceId}`)}`;
        }
      },
      {
        title: tlang`File Name`,
        fieldName: 'documentTracker.description',
        sortable: true,
        classes: 'colpx-550 colpxmax-550 document-description',
        displayValue: (_table: WebModuleLitTable, item: ProjectDocumentRecordView) => {
          const value = item.state;
          if (item.isDocument) {
            if (item.state === DocumentState.Available) {
              return this.getPublicLink(item.description, item.publicFileURL);
            }
            return html`${value}`;
          }
          return html``;
        }
      },
      {
        title: tlang`Status`,
        fieldName: 'documentTracker.state',
        sortable: true,
        classes: 'colpx-550 colpxmax-550 document-state',
        displayValue: (_table: WebModuleLitTable, item: ProjectDocumentRecordView) => {
          const value = item.state;
          if (item.isDocument) {
            return html`<span class="document-state-${DocumentState[value].toLowerCase().replace(' ', '-')}">
              ${DocumentState[value]}
            </span>`;
          }
          return html``;
        }
      },
      {
        title: html` <webmodule-icon library="fa" name="fas-bars"></webmodule-icon>`,
        fieldName: 'documentTracker.id',
        sortable: false,
        classes: 'colpx-30 colpxmax-50 item-menu',
        displayValue: (_table: WebModuleLitTable, item: ProjectDocumentRecordView) => {
          if (item.isDocument) {
            return this.ellipsisMenu(item);
          }
          return html``;
        }
      }
    ];
  }

  ellipsisMenu(row: ProjectDocumentRecordView): TemplateResult {
    const canDelete =
      row.category === DocumentCategory.Temporary &&
      (row.state === DocumentState.Available ||
        row.state === DocumentState.Failed ||
        row.state === DocumentState.Deleted);

    const template = html` <webmodule-dropdown
      placement="bottom-end"
      hoist
      @webmodule-select=${(e: WebmoduleSelectEvent) => this.onMenuItemSelected(e, row)}
    >
      <webmodule-icon-button slot="trigger" library="fa" name="fas-bars"></webmodule-icon-button>
      <webmodule-menu>
        ${when(
          canDelete,
          () =>
            html` <webmodule-menu-item class="action-delete" value="delete">
              <webmodule-icon slot="prefix" library="fa" name="fas-delete"></webmodule-icon>
              Copy
            </webmodule-menu-item>`
        )}
      </webmodule-menu>
    </webmodule-dropdown>`;
    return template;
  }

  async onMenuItemSelected(event: WebmoduleSelectEvent, resource: ProjectDocumentRecordView) {
    if (event.detail.item.value === 'delete') {
      await this.deleteDocumentEvent(resource.id);
    }
  }

  protected getPublicLink(description: string, link: string) {
    return html`<a href="${link}">${this.htmlEncode(description)}</a>`;
  }

  protected ordering(): boolean {
    return false;
  }

  private getResourceLink(resourceData: string): TemplateResult {
    const getAuthor = (id: string) => this.userCache.getLocal(id)?.data.friendlyName;

    //The resource data consist of resourceType;ResourceId
    const data = resourceData.split(';');
    const enumValue: ResourceType = getEnum(ResourceType, data[0]);
    const resourceId = data[1];

    let rClass = '';
    let rNumber = '';
    let resourceType = '';

    if (enumValue === ResourceType.Quote) {
      const quoteSummary = this.quoteCache.getLocal(resourceId)?.data.quoteSummary;
      resourceType = resourceQuote;
      const author = quoteSummary ? getAuthor(quoteSummary.assignedToUserId) : undefined;
      rNumber = quoteSummary
        ? author
          ? tlang`${getQuoteNumberFormatted(quoteSummary)} - ${quoteSummary.title} (%%author%%: ${author})`
          : getQuoteNumberFormatted(quoteSummary)
        : '';
      rClass = 'quote';
    } else if (enumValue === ResourceType.PurchaseOrder) {
      const poSummary = this.purchaseOrderCache.getLocal(resourceId)?.data.purchaseOrder;
      resourceType = resourcePurchaseOrder;
      const poAuthor = poSummary ? getAuthor(poSummary.creationUserId) : undefined;
      rNumber = poSummary
        ? poAuthor
          ? tlang`${getPurchaseOrderNumberFormatted(
              poSummary as PurchaseOrder
            )} - ${poSummary.title} (%%author%%: ${poAuthor})`
          : getPurchaseOrderNumberFormatted(poSummary as PurchaseOrder)
        : '';

      rClass = 'purchase-order';
    } else {
      alert('Missing resource link mapping');
      return html`${enumValue} - ${resourceId}`;
    }

    return html`<a
      class="documents-${rClass}-link"
      href="${resolveURL(resourceType, resourceId)}"
      data-resource-id="${resourceId}"
      >${rNumber}</a
    >`;
  }
}
@customElement('wm-franchiseeprojectdocumentview')
export class FranchiseeProjectDocumentView extends ProjectDocumentView {
  private documentTable: FranchiseeProjectDocumentTable;

  constructor(options: ProjectDocumentViewOptions) {
    super(options);

    this.documentTable = this.getDocumentTableFactory();
  }

  public override async invalidate(): Promise<void> {
    await super.invalidate();
    this.documentTable.refreshData();
  }

  public async refreshData(): Promise<void> {
    await this.documentTable.refreshData();
  }

  public async refreshButtonClick() {
    this.documentTable.forceRefresh();
    await this.refreshData();
  }
  public buttonMenu(): Snippet {
    return html`${refreshButton(async () => {
      await this.refreshButtonClick();
    })}`;
  }
  private _eventCancel: EventCancellation = new EventCancellation();
  private _gatedRefreshEvent = createStagedEvent({
    eventFinally: () => notificationSignal(false),

    event: () => {
      this.dispatchCustom('!related-changed', false);
      this.refreshButtonClick();
    },
    cancelToken: this._eventCancel,
    testInterval: defaultTestInterval,
    threshold: 5000,
    eventTriggered: () => {
      this.dispatchCustom('!related-changed', true);
      notificationSignal(true);
    }
  });
  private _eventDocument = (data: unknown, _eventName: string) => {
    const documents = (data as SSEDocumentTracker).links;
    if (documents.some(x => x.documentTracker.projectId === this.projectManager.projectId)) this._gatedRefreshEvent();
  };
  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    WMEventSource.getInstance().addEventListener(
      [WMEventSource.projectDocument, WMEventSource.projectDocumentDel],
      this._eventDocument
    );
  }
  async dispose(): Promise<void> {
    WMEventSource.getInstance().removeEventListener(
      [WMEventSource.projectDocument, WMEventSource.projectDocumentDel],
      this._eventDocument
    );
    await super.dispose();
  }
  protected getDocumentTableFactory() {
    return new FranchiseeProjectDocumentTable({
      projectManager: this.projectManager,
      deleteDocumentEvent: async (id: string) => await this.deleteDocument(id)
    });
  }

  protected template(): EventTemplate {
    return html`
      <h2 class="section-header">${tlang`Generated !!document!!`}</h2>
      <div class="row documents-dtrg-table">${this.documentTable}</div>
    `;
  }

  private async deleteDocument(id: string): Promise<boolean> {
    if (
      await AskConfirmation(
        tlang`Are you sure you want to delete the %%document%%?`,
        confirmationButtons[ConfirmationButtonType.yesNo],
        undefined,
        tlang`Delete %%document%%?`
      )
    ) {
      const success = await this.projectManager.removeDocument(id);

      if (success) {
        fireQuickSuccessToast(tlang`%%document%% deleted`);
        await this.refreshData();
      }

      return success;
    }
    return false;
  }
}
